import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  SimpleGrid,
  Flex,
  Icon,
  Text,
  Spinner,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import axios from 'axios';

import IconBox from 'components/icons/IconBox';
import Statistics from 'components/dataDisplay/MiniStatistics';
import Notification from 'components/dataDisplay/Notification';

import {
  MdOutlineBarChart,
  MdCompareArrows,
  MdShoppingBag,
  MdMoveToInbox,
  MdDateRange,
  MdOutlineWarning,
  MdInfoOutline,
} from 'react-icons/md';
import { RiArrowUpSFill } from 'react-icons/ri';

interface NotificationData {
  id: number;
  message: string;
  category: string; // e.g. “low stock item”, “expiring item”
  created_at: string;
  is_read: boolean;
}

interface NotificationsTableProps {
  accessToken: string;
  tenant: number | string;
  apiUrl: string;
}

export default function NotificationsTable({
  accessToken,
  tenant,
  apiUrl,
}: NotificationsTableProps) {
  const [notifications, setNotifications] = useState<NotificationData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Stats
  const [lowStockCount, setLowStockCount] = useState<number>(0);
  const [expiringCount, setExpiringCount] = useState<number>(0);
  const [showUnreadOnly, setShowUnreadOnly] = useState<boolean>(true);

  // Calculated from notifications
  const displayedNotifications = showUnreadOnly
    ? notifications.filter((n) => !n.is_read)
    : notifications;

  // =========== FETCH LOGIC ===========
  const fetchNotifications = useCallback(async () => {
    if (!accessToken || !tenant || !apiUrl) return;
    setLoading(true);
    try {
      const res = await axios.get<{ data: { Notifications: NotificationData[] } }>(
        `${apiUrl}/notifications`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant },
        }
      );
      setNotifications(res.data.data.Notifications || []);
      setError(null);
    } catch (err) {
      console.error('Error fetching notifications:', err);
      setError('Failed to fetch notifications.');
    } finally {
      setLoading(false);
    }
  }, [accessToken, tenant, apiUrl]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  // =========== STATS: Low Stock, Expiring, etc. ===========
  useEffect(() => {
    const lowStock = notifications.filter(
      (n) => n.category.toLowerCase() === 'low stock item'
    ).length;

    const expiring = notifications.filter(
      (n) => n.category.toLowerCase() === 'expiring item'
    ).length;

    setLowStockCount(lowStock);
    setExpiringCount(expiring);
  }, [notifications]);

  // Unread Count
  const unreadCount = notifications.filter((n) => !n.is_read).length;

  // Helper for icons
  function getIconForCategory(category?: string) {
    if (!category) return <MdInfoOutline />;
    switch (category.toLowerCase()) {
      case 'low stock item':
        return <MdMoveToInbox />;
      case 'expiring item':
        return <MdDateRange />;
      case 'warning':
        return <MdOutlineWarning />;
      case 'info':
        return <MdInfoOutline />;
      default:
        return <MdInfoOutline />;
    }
  }

  // Chakra color
  const iconBg = useColorModeValue('secondaryGray.300', 'navy.700');
  const iconColor = useColorModeValue('brand.500', 'white');

  return (
    <Box pt={{ base: '10px', md: '10px', xl: '10px' }}>
      {/* STATISTICS */}
      <SimpleGrid
        columns={{ sm: 1, md: 2, lg: 2, xl: 2, '2xl': 4 }}
        gap="20px"
        mb="20px"
        w="100%"
        justifyItems="center"
        alignItems="center"
      >
        {/* Low Stock */}
        <Statistics
          focused
          bgGradient="linear(to-b, brand.400, brand.600)"
          title="Low Stock Items"
          value={`${lowStockCount}`}
          detail={
            <Flex align="center">
              <Icon as={RiArrowUpSFill} color="white" />
              <Text color="white" fontSize="sm" mx="3px" fontWeight="500">
                +{lowStockCount}
              </Text>
              <Text color="white" fontSize="sm" fontWeight="500">
                Since last check
              </Text>
            </Flex>
          }
          illustration={
            <IconBox
              w="80px"
              h="80px"
              bgGradient="linear(to-b, brand.600, brand.400)"
              icon={<Icon as={MdOutlineBarChart} w="38px" h="38px" color="white" />}
            />
          }
        />

        {/* Expiring */}
        <Statistics
          title="Expiring Items"
          value={`${expiringCount}`}
          detail={
            <Flex align="center">
              <Icon as={RiArrowUpSFill} color="green.500" />
              <Text color="green.500" fontSize="sm" mx="4px" fontWeight="700">
                +{expiringCount}
              </Text>
              <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
                Potentially expiring soon
              </Text>
            </Flex>
          }
          illustration={
            <IconBox
              w="80px"
              h="80px"
              bg={iconBg}
              icon={<Icon color={iconColor} as={MdCompareArrows} w="38px" h="38px" />}
            />
          }
        />

        {/* Total Notifications */}
        <Statistics
          title="Total Notifications"
          value={`${notifications.length}`}
          detail={
            <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
              All current alerts
            </Text>
          }
          illustration={
            <IconBox
              w="80px"
              h="80px"
              bg={iconBg}
              icon={<Icon color={iconColor} as={MdShoppingBag} w="28px" h="28px" />}
            />
          }
        />

        {/* NEW: Unread Notifications */}
        <Statistics
          title="Unread Notifications"
          value={`${unreadCount}`}
          detail={
            <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
              Not yet seen
            </Text>
          }
          illustration={
            <IconBox
              w="80px"
              h="80px"
              bg={iconBg}
              icon={<Icon color={iconColor} as={MdInfoOutline} w="28px" h="28px" />}
            />
          }
        />
      </SimpleGrid>

      {/* Toggle Button to Show Unread or All */}
      <Button variant="brand" mb="10px" onClick={() => setShowUnreadOnly((prev) => !prev)}>
        {showUnreadOnly ? 'Show All Notifications' : 'Show Unread Only'}
      </Button>

      {/* Spinner / Error Handling */}
      {loading && (
        <Flex justify="center" p="10px">
          <Spinner />
        </Flex>
      )}
      {error && (
        <Text color="red.500" mb="10px">
          {error}
        </Text>
      )}

      {/* No results message */}
      {!loading && !error && displayedNotifications.length === 0 && (
        <Text>
          No {showUnreadOnly ? 'unread' : 'read'} notifications available.
        </Text>
      )}

      {/* Render the notifications list */}
      {displayedNotifications.map((notif) => {
        const createdDate = new Date(notif.created_at).toLocaleString();
        return (
          <Box key={notif.id} mb="10px">
            <Notification
              date={createdDate}
              icon={getIconForCategory(notif.category)}
              message={notif.message}
              category={notif.category}
              is_read={notif.is_read}
            />
          </Box>
        );
      })}
    </Box>
  );
}
