// src/views/admin/jobs/components/JobHistoryTable.tsx
import  { useState } from 'react';
import {
  Box,
  Text,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import { Job } from 'components/types/jobs';
import JobDetailsModal from './jobDetailsModal';
import JobDisplay from 'components/dataDisplay/JobDisplay';
interface JobHistoryTableProps {
  jobs: Job[];
  refreshJobs: () => void;
  fakeSave?: boolean;
}

export default function JobHistoryTable({ jobs, refreshJobs, fakeSave }: JobHistoryTableProps) {
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      {jobs.map((job) => (
        <Flex
        key={job.id}
        onClick={() => {
          setSelectedJob(job);
          onOpen();
        }}
        cursor="pointer"
        p="4"
        borderWidth="1px"
        borderRadius="md"
        mb="2"
        align="center"
        justify="space-between"
        >
          <JobDisplay date={job.date} name={job.name} status={job.status} cost={job.cost}></JobDisplay>

        </Flex>
      ))}

      <JobDetailsModal
        isOpen={isOpen}
        onClose={onClose}
        job={selectedJob}
        refreshJobs={refreshJobs}
        fakeSave={fakeSave}
      />
    </Box>
  );
}
