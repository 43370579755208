import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
  Box,
  Text,
  Input,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useApi } from 'components/hooks/useApi';
import { useToken } from 'components/hooks/useToken';
import { useTenant } from 'components/hooks/useTenant';
import { OrderItem } from 'components/types/orders';

interface ExistingItem {
  id: number;
  name: string;
  batch_number: string;
  quantity: number;
  cost_price: number;
  selling_price: number;
  min_level: number;
  category_name: string;
  // ...
}

interface ReceivedItemInfo {
  batchNumber: string;
  expiryDate: string;
}

interface OrderDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  orderId: number | null;
  /** The current status of this order, e.g. 'Pending' or 'Received' */
  orderStatus: string;
}

export default function OrderDetailsModal({
  isOpen,
  onClose,
  orderId,
  orderStatus,
}: OrderDetailsModalProps) {
  const [items, setItems] = useState<OrderItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  /** Controls the "2-step" flow. */
  const [isMarkAsReceivedClicked, setIsMarkAsReceivedClicked] = useState(false);
  /** For each order item, store user input for batchNumber / expiryDate. */
  const [receivedItemDetails, setReceivedItemDetails] = useState<ReceivedItemInfo[]>([]);

  const [itemsMap, setItemsMap] = useState<{ [key: number]: ExistingItem }>({});

  const apiUrl = useApi();
  const accessToken = useToken();
  const tenant = useTenant();
  const toast = useToast();

  // Helper to reset the 2-step flow state when the modal closes
  const handleCloseModal = () => {
    setIsMarkAsReceivedClicked(false);
    setReceivedItemDetails([]);
    onClose(); // Actually close the modal
  };

  // We consider the order “already received” if status is "received" or "Received"
  const isOrderAlreadyReceived = orderStatus.toLowerCase() === 'received';

  /* ----------------------------------------------------------------
    1) FETCH ORDER ITEMS
  ------------------------------------------------------------------ */
  useEffect(() => {
    if (!isOpen || orderId == null) return;

    const fetchOrderItems = async () => {
      setLoading(true);
      try {
        const response = await axios.get<{ data: { OrderItems: OrderItem[] } }>(
          `${apiUrl}/order_items`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: { order_id: orderId, tenant },
          }
        );
        const fetched = response.data.data.OrderItems || [];
        setItems(fetched);
        setError(null);
      } catch (err: any) {
        setError(err.response?.data?.message || 'Error fetching order items');
      } finally {
        setLoading(false);
      }
    };

    fetchOrderItems();
  }, [isOpen, orderId, apiUrl, accessToken, tenant]);

  /* ----------------------------------------------------------------
    2) FETCH ALL EXISTING ITEMS 
       (so we can reuse cost_price, etc. if needed)
  ------------------------------------------------------------------ */
  useEffect(() => {
    if (!isOpen) return;

    const fetchAllItems = async () => {
      try {
        const response = await axios.get<{ data: { Items: ExistingItem[] } }>(
          `${apiUrl}/inventory/items`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: { tenant },
          }
        );
        const list = response.data.data.Items || [];
        const map: { [key: number]: ExistingItem } = {};
        list.forEach((item) => {
          map[item.id] = item;
        });
        setItemsMap(map);
      } catch (err) {
        console.error('Error fetching all items:', err);
      }
    };

    fetchAllItems();
  }, [isOpen, apiUrl, accessToken, tenant]);

  /* ----------------------------------------------------------------
    3) "Mark as Received" => show batch prompts
  ------------------------------------------------------------------ */
  const handleMarkAsReceived = () => {
    // Initialize empty details for each item
    const initialDetails = items.map(() => ({
      batchNumber: '',
      expiryDate: '',
    }));
    setReceivedItemDetails(initialDetails);

    setIsMarkAsReceivedClicked(true);
  };

  /* ----------------------------------------------------------------
    4) "Confirm" => update order status & handle batch logic
  ------------------------------------------------------------------ */
  const handleConfirm = async () => {
    if (!orderId) return;

    // Quick validation

    setLoading(true);
    try {
      // (A) Update order's status => "Received"
      await axios.put(
        `${apiUrl}/ordersStatus`,
        {
          orders: [
            {
              id: orderId,
              status: 'Received',
            },
          ],
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant },
        }
      );

      // (B) For each item => check or create new batch
      for (let i = 0; i < items.length; i++) {
        const orderItem = items[i];
        const { batchNumber, expiryDate } = receivedItemDetails[i];
        const quantityToAdd = orderItem.item_quantity || 0;

        // Check if batchNumber already exists:
        let existingBatch: ExistingItem | null = null;
        try {
          const checkResp = await axios.get<{ data: { Items: ExistingItem[] } }>(
            `${apiUrl}/inventory/itemsByBatchNumber`, // or your new custom endpoint
            {
              headers: { Authorization: `Bearer ${accessToken}` },
              params: { tenant, batch_number: batchNumber },
            }
          );
          const found = checkResp.data.data.Items || [];
          existingBatch = found.length > 0 ? found[0] : null;
        } catch (err) {
          console.error('Error checking existing batch item:', err);
        }

        if (existingBatch) {
          // update quantity
          const newQty = existingBatch.quantity + quantityToAdd;
          await axios.put(
            `${apiUrl}/inventory/itemsQuantity`,
            {
              items: [
                {
                  Id: existingBatch.id,
                  Quantity: newQty,
                },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
              },
              params: { tenant },
            }
          );
        } else {
          // create a new item row with same cost_price, etc.
          const baseItem = itemsMap[orderItem.item_id];
          if (!baseItem) {
            console.warn(`No baseItem found for item_id=${orderItem.item_id}`);
            continue;
          }
          const localDate = receivedItemDetails[i].expiryDate;
          let isoString: string | null = null;
          if (localDate) {
            isoString = `${localDate}T00:00:00Z`
          }
          const { batchNumber } = receivedItemDetails[i];
          const finalBatch = batchNumber.trim() || '000'; 
          const payload = {
            Items: [
              {
                name: baseItem.name,
                quantity: quantityToAdd,
                cost_price: baseItem.cost_price,
                selling_price: baseItem.selling_price,
                min_level: baseItem.min_level,
                category_name: baseItem.category_name,
                batch_number: finalBatch,
                expiry_date: isoString,
              },
            ],
          };

          await axios.post(`${apiUrl}/inventory/items`, payload, {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: { tenant },
          });
        }
      }

      toast({
        title: 'Order marked as received.',
        description: 'All items updated/created with the provided batch numbers.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      // Finally close the modal & reset state
      handleCloseModal();
    } catch (err: any) {
      console.error(err);
      toast({
        title: 'Failed to confirm received order',
        description:
          err.response?.data?.message || 'An error occurred updating this order.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Order Details (ID: {orderId}) 
          <br />
          Status: {orderStatus}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading ? (
            <Spinner />
          ) : error ? (
            <Box color="red.500">{error}</Box>
          ) : items.length === 0 ? (
            <Box>No items found for this order.</Box>
          ) : (
            <>
              {items.map((item, idx) => {
                const baseItem = itemsMap[item.item_id];
                const itemName = baseItem ? baseItem.name : `Item #${item.item_id}`;
                return (
                  <Box key={idx} borderWidth="1px" p="2" mb="4" borderRadius="md">
                    <Text fontWeight="bold">
                      {itemName} Ordered Qty:{' '}
                      {item.item_quantity}
                    </Text>

                    {/** 
                      Step 2 flow: if user has clicked MarkAsReceived
                      (and order not already received), show batch fields
                    */}
                    {!isOrderAlreadyReceived && isMarkAsReceivedClicked && (
                      <Box mt="2">
                        <Text fontSize="sm" mt="1">
                          Batch Number:
                        </Text>
                        <Input
                          placeholder="000"
                          value={receivedItemDetails[idx]?.batchNumber || ''}
                          onChange={(e) => {
                            const val = e.target.value;
                            setReceivedItemDetails((prev) => {
                              const updated = [...prev];
                              updated[idx] = {
                                ...updated[idx],
                                batchNumber: val,
                              };
                              return updated;
                            });
                          }}
                        />

                        <Text fontSize="sm" mt="1">
                          Expiry Date (optional):
                        </Text>
                        <Input
                          type="date"
                          value={receivedItemDetails[idx]?.expiryDate || ''} // store "YYYY-MM-DD"
                          onChange={(e) => {
                            const localDate = e.target.value; // e.g. "2029-10-31"

                            setReceivedItemDetails((prev) => {
                              const updated = [...prev];
                              updated[idx] = {
                                ...updated[idx],
                                expiryDate: localDate, // <-- store as plain YYYY-MM-DD
                              };
                              return updated;
                            });
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                );
              })}
            </>
          )}
        </ModalBody>

        <ModalFooter>
          {/* If order is already received, show no "Mark as Received" button. */}
          {isOrderAlreadyReceived ? (
            <Button onClick={handleCloseModal}>Close</Button>
          ) : (
            <>
              {/* If MarkAsReceived not clicked, show "Mark as Received" */}
              {!isMarkAsReceivedClicked && (
                <Button
                  colorScheme="brand"
                  mr={3}
                  isLoading={loading}
                  onClick={handleMarkAsReceived}
                >
                  Mark as Received
                </Button>
              )}

              {/* If MarkAsReceived was clicked, show "Confirm" */}
              {isMarkAsReceivedClicked && (
                <Button
                  colorScheme="blue"
                  mr={3}
                  isLoading={loading}
                  onClick={handleConfirm}
                >
                  Confirm
                </Button>
              )}

              <Button onClick={handleCloseModal} isDisabled={loading}>
                Close
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
