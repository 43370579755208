import { Box, Image, Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import inventoryImage from "../../../../assets/img/buttons/inventory.png"
import jobsImage from "../../../../assets/img/buttons/jobs.png"
import orderImage from "../../../../assets/img/buttons/order.png"
import stockImage from "../../../../assets/img/buttons/stock.png"

export default function QuickLinks() {
  return (
      <Box
          display="flex"
          justifyContent="center"
          gap={4}
          mt={10}
          mb={10}
          flexWrap="wrap"
      >
          {/* Inventory */}
          <Box width={["100%", "45%", "250px"]} textAlign="center">
              <Link to="/admin/inventory/all">
                  <Image
                      src={inventoryImage}
                      alt="Inventory"
                      width="100%"
                      objectFit="cover"
                  />
              <Button
                  as="a"
                  href="/admin/inventory/all"
                  width="100%"
                  mt={2}
                  bg="brand.500"
                  color="white"
                  _hover={{ bg: 'brand.600' }}
                  >
                  Inventory
              </Button>
                </Link>
          </Box>

          {/* Jobs */}
          <Box width={["100%", "45%", "250px"]} textAlign="center">
              <Link to="/admin/jobs/history">
                  <Image
                      src={jobsImage}
                      alt="Jobs"
                      width="100%"
                      objectFit="cover"
                  />
              <Button
                  as="a"
                  href="/admin/jobs/history"
                  width="100%"
                  mt={2}
                  bg="brand.500"
                  color="white"
                  _hover={{ bg: 'brand.600' }}
              >
                  Jobs
              </Button>
              </Link>
          </Box>

          {/* Orders */}
          <Box width={["100%", "45%", "250px"]} textAlign="center">
              <Link to="/admin/orders/history">
                  <Image
                      src={orderImage}
                      alt="Orders"
                      width="100%"
                      objectFit="cover"
                  />
              <Button
                  as="a"
                  href="/admin/orders/history"
                  width="100%"
                  mt={2}
                  bg="brand.500"
                  color="white"
                  _hover={{ bg: 'brand.600' }}
              >
                  Orders
              </Button>
              </Link>
          </Box>

          {/* Stock */}
          <Box width={["100%", "45%", "250px"]} textAlign="center">
              <Link to="/admin/stock/levels">
                  <Image
                      src={stockImage}
                      alt="Stock"
                      width="100%"
                      objectFit="cover"
                  />
              <Button
                  as="a"
                  href="/admin/stock/levels"
                  width="100%"
                  mt={2}
                  bg="brand.500"
                  color="white"
                  _hover={{ bg: 'brand.600' }}
              >
                  Stock
              </Button>
              </Link>
          </Box>
      </Box>
  );
}