import { Box } from "@chakra-ui/react"
import NotificationsTable from "./components/notificationsTable"
import { useApi } from "components/hooks/useApi"
import { useToken } from "components/hooks/useToken"
import { useTenant } from "components/hooks/useTenant"
export default function NotificationsPage() {
    const accessToken = useToken();
    const tenant = useTenant();
    const apiUrl = useApi();
    return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <NotificationsTable 
            accessToken={accessToken}
            tenant={tenant}
            apiUrl={apiUrl}
        />
    </Box>
    )
}