import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import {} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import {
  ChakraProvider,
  extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
import ProtectedRoute from 'components/auth/ProtectedRoute';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from 'components/Loading';


const colors = {
  "pink": {
    "50": "#FBE9F3",
    "100": "#F5C2DC",
    "200": "#EE9BC6",
    "300": "#E774AF",
    "400": "#E04C99",
    "500": "#DA2582",
    "600": "#AE1E68",
    "700": "#83164E",
    "800": "#570F34",
    "900": "#2C071A"
  },
  "green": {
    "50": "#EBF9F2",
    "100": "#C7EFDA",
    "200": "#A4E5C2",
    "300": "#80DBAA",
    "400": "#5CD192",
    "500": "#38C77B",
    "600": "#2D9F62",
    "700": "#22774A",
    "800": "#174F31",
    "900": "#0B2819"
  },
  "red": {
    "50": "#FDE8E8",
    "100": "#F8BEBE",
    "200": "#F49595",
    "300": "#EF6C6C",
    "400": "#EB4242",
    "500": "#E61919",
    "600": "#B81414",
    "700": "#8A0F0F",
    "800": "#5C0A0A",
    "900": "#2E0505"
  }
}

const mergedTheme = extendTheme({
  ...initialTheme,
  colors: {
    ...initialTheme.colors,
    ...colors,
  }
})

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(mergedTheme);
  const HomeRedirect = () => {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
    if (isLoading) return <Loading message="Loading..." />;
    if (isAuthenticated) return <Navigate to="/admin/home" replace />;
    loginWithRedirect();
  };



  return (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route
          path="admin/*"
          element={
            <ProtectedRoute>
            <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
            </ProtectedRoute>
          }
        />
        <Route
          path="rtl/*"
          element={
            <RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route path="/" element={<HomeRedirect />} />
      </Routes>
    </ChakraProvider>
  );
}
