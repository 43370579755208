import { Button, Icon } from '@chakra-ui/react';
import { MdHelp } from 'react-icons/md';

import React, { useState } from 'react';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { IconButton } from '@chakra-ui/react';


interface HelpTourProps {
  steps: Step[];
  colorScheme?: string;
}

const HelpButton: React.FC<HelpTourProps> = ({ steps, colorScheme = 'brand' }) => {
  const [run, setRun] = useState(false);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRun(false);
    }
  };

  return (
    <>
      <IconButton
        aria-label="Help"
        icon={<MdHelp />}
        variant="ghost"
        color={`${colorScheme}.500`}
        onClick={() => setRun(true)}
      />

      <Joyride
        steps={steps}
        run={run}
        continuous
        showSkipButton
        showProgress
        disableScrolling={true}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#db30b5' // Example brand color
          },
          buttonNext: {
            backgroundColor: '#db30b5',
            color: 'white'
          },
          buttonBack: {
            color: '#db30b5'
          },
          buttonSkip: {
            color: '#db30b5'
          }
        }}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finish',
          next: 'Next',
          skip: 'Skip'
        }}
      />
    </>
  );
};

export default HelpButton;