// GettingStarted.tsx
import React from 'react';
import { Box, Heading, useColorModeValue } from '@chakra-ui/react';
import { AspectRatio } from '@chakra-ui/react';
const GettingStartedPage: React.FC = () => {
  const headingColor = useColorModeValue('gray.700', 'white');

  return (
    <Box>
      <Heading as="h3" size="lg" color={headingColor} mb={4}>
        Getting Started
      </Heading>
      <AspectRatio>
        <iframe
          title="Getting Started Video"
          src="https://www.youtube.com/embed/Mgwiu1ysfAY"
          allowFullScreen
        />
      </AspectRatio>
    </Box>
  );
};

export default GettingStartedPage;