import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  Stack,
  Select,
  IconButton,
  useColorModeValue,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import InputField from 'components/fields/InputField';
import axios from 'axios';
import { useApi } from 'components/hooks/useApi';
import { useToken } from 'components/hooks/useToken';
import { useTenant } from 'components/hooks/useTenant';

interface Item {
  id: number;
  name: string;
  batch_number: string;
}

interface StockMovementRow {
  item_id: string;
  reason: string;
  quantity: string;
  sales_amount: string;
}

interface AddStockMovementFormProps {
  refreshStockMovements: () => void;
}

export default function AddStockMovementForm({ refreshStockMovements }: AddStockMovementFormProps) {
  const apiUrl = useApi();
  const accessToken = useToken();
  const tenant = useTenant();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  // State for multiple rows of stock movements
  const [rows, setRows] = useState<StockMovementRow[]>([
    { item_id: '', reason: '', quantity: '', sales_amount: '' },
  ]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Items state
  const [items, setItems] = useState<Item[]>([]);
  const [loadingItems, setLoadingItems] = useState<boolean>(true);
  const [itemsError, setItemsError] = useState<string | null>(null);

  // Fetch items to populate the dropdown
  const fetchItems = useCallback(async (): Promise<void> => {
    if (!accessToken || !tenant) return;
    try {
      const response = await axios.get<{ data: { Items: Item[] | null } }>(
        `${apiUrl}/inventory/stock`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant },
        }
      );
  
      // Ensure items is always an array
      setItems(response.data.data.Items || []);
      setItemsError(null);
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        setItemsError(err.response?.data?.message || 'Error fetching items.');
      } else if (err instanceof Error) {
        setItemsError(err.message);
      } else {
        setItemsError('An unknown error occurred while fetching items.');
      }
    } finally {
      setLoadingItems(false);
    }
  }, [accessToken, tenant, apiUrl]);
  

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const handleRowChange = (
    index: number,
    field: keyof StockMovementRow,
    value: string
  ) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = { ...updatedRows[index], [field]: value };
      return updatedRows;
    });
  };

  const addRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      { item_id: '', reason: '', quantity: '', sales_amount: '' },
    ]);
  };

  const removeRow = (index: number) => {
    setRows((prevRows) => prevRows.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    for (const row of rows) {
      if (!row.item_id.trim() || !row.reason.trim() || !row.quantity.trim()) {
        toast({
          title: 'Validation Error',
          description: 'Each row must have an item, reason, and quantity.',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    }

    const movementsPayload = rows.map((row) => ({
      tenant_id: tenant,
      item_id: parseInt(row.item_id, 10),
      reason: row.reason,
      quantity: parseInt(row.quantity, 10),
      sales_amount: parseFloat(row.sales_amount) || 0,
    }));

    setIsSubmitting(true);
    try {
      const payload = { StockMovements: movementsPayload };
      const response = await axios.post(`${apiUrl}/stock_movements`, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        params: { tenant },
      });

      if ([200, 201, 202].includes(response.status)) {
        toast({
          title: 'Stock Movements Added',
          description: 'The stock movements have been recorded successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setRows([{ item_id: '', reason: '', quantity: '', sales_amount: '' }]);
        refreshStockMovements();
        onClose();
      } else {
        throw new Error('Failed to add stock movements.');
      }
    } catch (error: any) {
      toast({
        title: 'Error',
        description:
          error.response?.data?.message || 'An error occurred while adding the stock movements.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModalClose = () => {
    setRows([{ item_id: '', reason: '', quantity: '', sales_amount: '' }]);
    onClose();
  };

  return (
    <>
      <Button
        variant="brand"
        fontSize="sm"
        borderRadius="16px"
        h="32px"
        onClick={onOpen}
        aria-label="Adjust Stock"
      >
        Manual Stock Adjustment
      </Button>

      <Modal isOpen={isOpen} onClose={handleModalClose} isCentered size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adjust stock item(s)</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="20px" mt="10px">
              {loadingItems ? (
                <Box>Loading items...</Box>
              ) : itemsError ? (
                <Box color="red.500">Error: {itemsError}</Box>
              ) : (
                rows.map((row, index) => (
                  <Stack key={index} spacing="4" borderWidth="1px" p="4" borderRadius="md">
                    {/* First line: Item selection */}
                    <Select
                      placeholder="Select Item"
                      value={row.item_id}
                      onChange={(e) => handleRowChange(index, "item_id", e.target.value)}
                      isRequired
                    >
                      {!loadingItems && items.length > 0 ? (
                        items.map((item) => (
                          <option key={item.id} value={item.id}>
                            {`${item.name} - Batch: ${item.batch_number}`}
                          </option>
                        ))
                      ) : (
                        <option disabled>No stock items available</option>
                      )}
                    </Select>

                    {/* Second line: Reason, Quantity, Sales Amount */}
                    <Stack direction={{ base: "column", md: "row" }} spacing="20px" align="center">
                      <InputField
                        label="Reason"
                        placeholder="e.g., sale, restock, adjustment"
                        value={row.reason}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleRowChange(index, 'reason', e.target.value)
                        }
                        isRequired
                      />
                      <InputField
                        label="Quantity"
                        placeholder="Enter quantity"
                        type="number"
                        value={row.quantity}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleRowChange(index, 'quantity', e.target.value)
                        }
                        isRequired
                      />
                      <InputField
                        label="Sales Amount"
                        placeholder="Enter sales amount"
                        type="number"
                        value={row.sales_amount}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleRowChange(index, 'sales_amount', e.target.value)
                        }
                      />
                      {rows.length > 1 && (
                        <IconButton
                          aria-label="Remove Row"
                          icon={<CloseIcon />}
                          onClick={() => removeRow(index)}
                          colorScheme="red"
                          mt={{ base: 4, md: 0 }}
                        />
                      )}
                    </Stack>
                  </Stack>
                ))
              )}
              {!loadingItems && !itemsError && (
                <Button onClick={addRow} variant="outline" colorScheme="brand">
                  Add Another Item
                </Button>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleModalClose} aria-label="Cancel">
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={isSubmitting}
              loadingText="Submitting"
              aria-label="Submit Stock Movements"
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
