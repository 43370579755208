import React, { forwardRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';

interface CalendarEvent {
	title: string;
	borderColor: string;
	start: string;
	end: string;
	backgroundColor: string;
	className: string;
	extendedProps?: {
		type?: "job" | "order";
		jobId?: number;
		orderId?: number;
	  };
  }
  
  interface EventCalendarProps {
	calendarData: CalendarEvent[];
	initialDate: string;
	onEventClick?: (info: any) => void;
	onDatesSet?: (info: any) => void;
  }

  const EventCalendar = forwardRef<FullCalendar, EventCalendarProps>(
	(props, ref) => {
	  const { calendarData, initialDate, onEventClick, onDatesSet } = props;
  
	  return (
		<FullCalendar
		eventDisplay='block'
		  datesSet={onDatesSet}
		  ref={ref}
		  plugins={[dayGridPlugin, interactionPlugin]}
		  headerToolbar={false}
		  initialView="dayGridMonth"
		  contentHeight="600px"
		  events={calendarData}
		  initialDate={initialDate}
		  editable
		  eventClick={onEventClick}
		  displayEventTime={false}
		/>
	  );
	}
  );
  
  export default EventCalendar;