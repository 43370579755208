import { Job } from 'components/types/jobs'
export const exampleJobsTableData: Job[] = [
    {
        id: 1,
        tenant_id: 1,
        name: 'Bee Box Assembly',
        status: 'Open',
        date: '2023-02-10',
        created_at: '2023-02-10',
        updated_at: '2023-02-10',
        cost: 123,
        items: [
            {
            job_id: 1,
            item_id: 101,
            quantity: 3,
            created_at: '2023-02-10',
            },
            {
            job_id: 1,
            item_id: 102,
            quantity: 2,
            created_at: '2023-02-10',
            },
        ],
    },
    {
    id: 2,
    tenant_id: 1,
    name: 'Winter Feeding',
    status: 'Completed',
    date: '2023-01-15',
    created_at: '2023-01-12',
    updated_at: '2023-01-16',
    cost: 456,
    items: [
        {
        job_id: 2,
        item_id: 103,
        quantity: 5,
        created_at: '2023-01-12',
        },
    ],
    },
    {
    id: 3,
    tenant_id: 1,
    name: 'Harvest Honey',
    status: 'In Progress',
    date: '2023-03-01',
    created_at: '2023-02-28',
    updated_at: '2023-03-02',
    cost: 789,
    items: [
        {
        job_id: 3,
        item_id: 201,
        quantity: 10,
        created_at: '2023-02-28',
        },
        {
        job_id: 3,
        item_id: 202,
        quantity: 2,
        created_at: '2023-02-28',
        },
    ],
    },
    {
    id: 4,
    tenant_id: 2,
    name: 'Packaging Candles',
    status: 'Open',
    date: '2023-02-20',
    created_at: '2023-02-20',
    updated_at: '2023-02-20',
    cost: 23,
    items: [
        {
        job_id: 4,
        item_id: 301,
        quantity: 30,
        created_at: '2023-02-20',
        },
    ],
    },
    {
    id: 5,
    tenant_id: 2,
    name: 'Inspect Hives',
    status: 'Open',
    date: '2023-04-10',
    cost: 123,
    created_at: '2023-04-10',
    updated_at: '2023-04-10',
    items: [],
    },
    {
    id: 6,
    tenant_id: 1,
    name: 'Propolis Extraction',
    status: 'Completed',
    date: '2023-01-25',
    created_at: '2023-01-24',
    updated_at: '2023-01-25',
    cost: 666,
    items: [
        {
        job_id: 6,
        item_id: 203,
        quantity: 1,
        created_at: '2023-01-24',
        },
        {
        job_id: 6,
        item_id: 204,
        quantity: 2,
        created_at: '2023-01-24',
        },
    ],
    },
];
