import { Step } from 'react-joyride';
import { Link } from 'react-router-dom';

export const tourSteps: Step[] = [
  {
    target: 'img[alt="Inventory"]',
    content: 'This is your inventory, where all of your items live that your business currently owns. If this is your first time using BeeStocked then you probably want to start here.',
    placement: 'bottom'
  },
  {
    target: 'img[alt="Jobs"]',
    content: 'Next we have Jobs, in this section you can add, edit, and view jobs which are the primary way for you to tell BeeStocked that you have used items',
    placement: 'bottom'
  },
  {
    target: 'img[alt="Orders"]',
    content: 'Orders is where you would manage suppliers, and add orders to tell BeeStocked that new things are coming into your inventory.',
    placement: 'bottom'
  },
  {
    target: 'img[alt="Stock"]',
    content: 'Stock is all the items in your inventory that you sell to clients, and this is where you can find and manage them.',
    placement: 'bottom'
  },
  {
    // Final step with a "body" or null target
    target: 'body',
    content: (
      <div>
        <p>Well done, you've finished the tour!</p>
        <p>
          Check out the{' '}
          <Link to="/admin/help/docs">
            <strong>documentation (click me!)</strong>
          </Link>{' '}
          to learn more.
        </p>
        <p>Alternatively check out our{' '}
          <Link to="https://www.youtube.com/embed/Mgwiu1ysfAY">
          <strong>video tutorials (click me!)</strong></Link>{' '}
          to get stuck in straight away.
        </p>
      </div>
    ),
    placement: 'center',
  },
];