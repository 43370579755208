import {
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import { useEffect, useState, useMemo } from 'react';
import { MdEdit, MdDelete } from 'react-icons/md';
import EditItemModal from 'views/admin/inventory/allitems/components/editItemModal';
import { Item } from 'components/types/items';

interface RowObj {
  id: number;
  name: string;
  category_name: string;
  quantity: number;
  cost_price: number;
  selling_price: number;
  min_level: number;
  expiry_date: string;
  batch_number: string;
  tenant_id: number;
  supplier_id: number;
  created_at: string;
  updated_at: string;
}

interface ItemTableProps {
  tableData: RowObj[];
  tableName: string;
  accessToken: string;
  tenant: string | number;
  refreshItems: () => void;
  fakeSave?: boolean;
}

const columnHelper = createColumnHelper<RowObj>();

export default function ItemTable({
  tableData,
  tableName,
  accessToken,
  tenant,
  refreshItems,
  fakeSave,
}: ItemTableProps): JSX.Element {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [data, setData] = useState<RowObj[]>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const { onOpen } = useDisclosure();
  const [ , setItemToDelete] = useState<RowObj | null>(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<Item | null>(null);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    setData(Array.isArray(tableData) ? tableData : []);
  }, [tableData]);

  const handleDeleteClick = (item: RowObj) => {
    setItemToDelete(item);
    onOpen();
  };

  const handleEditClick = (item: Item) => {
    setItemToEdit(item);
    setEditModalOpen(true);
  };
  const columns = useMemo(() => {
    const baseColumns: ColumnDef<RowObj, any>[] = [
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Item Name',
        cell: (info) => (
          <Text fontSize="sm" fontWeight="700" color={textColor}>
            {info.getValue()}
          </Text>
        ),
      }),
      columnHelper.accessor('quantity', {
        id: 'quantity',
        header: 'Quantity',
        cell: (info) => (
          <Text fontSize="sm" fontWeight="700" color={textColor}>
            {info.getValue() as number}
          </Text>
        ),
      }),
      columnHelper.accessor('expiry_date', {
        id: 'expiry_date',
        header: 'Expiry Date',
        cell: (info) => (
          <Text fontSize="sm" fontWeight="700" color={textColor}>
            {new Date(info.getValue() as string).toLocaleDateString()}
          </Text>
        ),
      }),
      columnHelper.display({
        id: 'edit',
        header: 'Edit',
        cell: (info) => {
          const rowData = info.row.original;
          return (
            <Icon
              as={MdEdit}
              w="20px"
              h="20px"
              cursor="pointer"
              color={textColor}
              onClick={() => handleEditClick(rowData)}
              aria-label={`Edit ${rowData.name}`}
            />
          );
        },
      }),
      columnHelper.display({
        id: 'delete',
        header: 'Delete',
        cell: (info) => {
          const rowData = info.row.original;
          return (
            <Icon
              as={MdDelete}
              w="20px"
              h="20px"
              cursor="pointer"
              color="red.500"
              onClick={() => handleDeleteClick(rowData)}
              aria-label={`Delete ${rowData.name}`}
            />
          );
        },
      }),
    ];

    if (!isMobile) {
      baseColumns.splice(2, 0, columnHelper.accessor('category_name', {
        id: 'category_name',
        header: 'Category Name',
        cell: (info) => (
          <Text fontSize="sm" fontWeight="700" color={textColor}>
            {info.getValue() as string}
          </Text>
        ),
      }));
      baseColumns.splice(3, 0, columnHelper.accessor('batch_number', {
        id: 'batch_number',
        header: 'Batch Number',
        cell: (info) => (
          <Text fontSize="sm" fontWeight="700" color={textColor}>
            {info.getValue() as string}
          </Text>
        ),
      }));
    }

    return baseColumns;
  }, [textColor, isMobile]);

  const table = useReactTable({
    data,
    columns,
    state: { sorting, globalFilter, pagination },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Card flexDirection="column" w="100%" px="0px" overflowX="auto">
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700">{tableName}</Text>
        <Menu />
      </Flex>

      <Table variant="simple" color="gray.500">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id} borderColor={borderColor}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Td key={cell.id} borderColor={borderColor}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      {isEditModalOpen && itemToEdit && (
        <EditItemModal
          isOpen={isEditModalOpen}
          onClose={() => setEditModalOpen(false)}
          item={itemToEdit}
          accessToken={accessToken}
          tenant={tenant}
          refreshItems={refreshItems}
        />
      )}
    </Card>
  );
}
