import React, { useState } from 'react';
import Joyride, { CallBackProps, STATUS, BeaconRenderProps } from 'react-joyride';
import { tourSteps } from './tourSteps';
import { Button, Text, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { forwardRef } from 'react';
const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  55% {
    background-color: rgba(210, 77, 243, 0.9);
    transform: scale(1.6);
  }
`;

const Beacon = styled.span`
  animation: ${pulse} 1s ease-in-out infinite;
  background-color: rgba(218, 24, 201, 0.6);
  border-radius: 50%;
  display: inline-block;
  height: 2rem;
  width: 2rem;
`;

const BeaconComponent = forwardRef<HTMLButtonElement, BeaconRenderProps>((props, ref) => {
    return <Beacon ref={ref} {...props} />;
  });

const Tour: React.FC = () => {
  const [run, setRun] = useState(false);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRun(false);
    }
  };

  return (
    <Flex direction="column" align="center" justify="center" gap={2}>
      <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
        First time here?
      </Text>
      <Button
        bg="brand.500"
        color="white"
        _hover={{ bg: 'brand.600' }}
        onClick={() => setRun(true)}
      >
        Start Tour
      </Button>

      <Joyride
        beaconComponent={BeaconComponent}
        steps={tourSteps}
        run={run}
        showSkipButton
        showProgress
        continuous
        disableScrolling={true}
        callback={handleJoyrideCallback}
        styles={{
            options: {
                zIndex: 10000,

                primaryColor: '#db30b5',
              },
              buttonNext: {
                backgroundColor: '#db30b5',
                color: 'white',
                borderRadius: '4px',
                padding: '8px 12px',
              },
              buttonBack: {
                color:  '#db30b5',
                marginRight: 10,
              },
              buttonSkip: {
                color:  '#db30b5',
              },
        }}
        locale={{
            back: 'Back',
            close: 'Close',
            last: 'Finish', 
            next: 'Next',
            skip: 'Skip'
          }}
      />
    </Flex>
  );
};

export default Tour;
