import { Icon } from '@chakra-ui/react';
import {
  MdInventory,
  MdLock,
  MdCheck,
  MdDns,
  MdContentPaste,
  MdSupervisedUserCircle,
  MdOutlineSettings,
  MdHelp,
  MdWork,
  MdCalendarMonth
} from 'react-icons/md';

// Quick Tasks Imports

//import QuickAddItem from 'views/admin/quicktasks/additem/AddItemPage';
import HomePage from 'views/admin/home/homePage';

// Dashboard Imports

//import DashboardsDefault from 'views/admin/dashboards/default';
//import DashboardsAnalytics from 'views/admin/dashboards/analytics';

// Inventory Imports

import AllItems from 'views/admin/inventory/allitems';
import Categories from 'views/admin/inventory/categories';

// Stock Import

import StockLevels from 'views/admin/stock/stocklevels';
import StockMovements from 'views/admin/stock/movements';

// Users Imports

import NewUser from 'views/admin/users/addnew';
import AllUsers from 'views/admin/users/allusers';

// Orders Imports

import OrdersHistory from 'views/admin/orders/history';
import SuppliersPage from 'views/admin/orders/suppliers';

// Jobs Imports

import JobsHistory from 'views/admin/jobs/jobHistory';

// Settings Imports

import BusinessSettingsPage from 'views/admin/settings/business/BusinessSettingsPage';

// Logout Imports

import LogOutPage from 'views/admin/logout/LogOutPage';
import Documentation from 'views/admin/help/documentation/Documentation';

// Calendar Page

import CalendarPage from 'views/admin/calendar';
import NotificationsPage from 'views/admin/settings/notifications/notificationsPage';

const routes = [

// --- Quick Tasks ---
  {
  name: 'Home',
  path: '/home',
  icon: <Icon as={MdCheck} width="20px" height="20px" color="inherit" />,
  collapse: false,
  component: <HomePage />,
  layout: '/admin',
  },
  
  // --- Dashboards ---
//  {
//    name: 'Dashboards',
//   path: '/dashboards',
//    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
//    collapse: true,
//    items: [
//      {
//        name: 'Overview',
//        layout: '/admin',
//        path: '/dashboards/default',
//        component: <DashboardsDefault />,
//      },
//      {
//        name: 'Analytics',
//        layout: '/admin',
//        path: '/dashboards/analytics',
//        component: <DashboardsAnalytics />,
//      },
//    ],
//  },  

// // --- Calendar Page ---
  {
    name: 'Calendar',
    path: '/calendar',
    icon: <Icon as={MdCalendarMonth} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <CalendarPage />,
    layout: '/admin',
  },

// // --- Inventory Pages ---
  {
    name: 'Inventory',
    path: '/inventory',
    icon: <Icon as={MdInventory} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'All items',
        path: '/inventory/all',
        component: <AllItems />,
        layout: '/admin',
      },
      {
        name: 'Categories',
        path: '/inventory/categories',
        component: <Categories />,
        layout: '/admin'
      },
    ],
  },
  // // --- Jobs
  {
    name: 'Jobs',
    path: '/jobs',
    icon: <Icon as={MdWork} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Job History',
        path: '/jobs/history',
        component: <JobsHistory />,
        layout: '/admin',
      }
    ]
  },
  // // --- Orders 
  {
    name: 'Orders',
    path: '/orders',
    icon: <Icon as={MdContentPaste} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Order History',
        path: '/orders/history',
        component: <OrdersHistory />,
        layout: '/admin',
      },
      {
        name: 'Suppliers',
        path: '/orders/suppliers',
        component: <SuppliersPage />,
        layout: '/admin',
      }
    ],
  },
  // // --- Stock Management 
  {
    name: 'Stock',
    path: '/stock',
    icon: <Icon as={MdDns} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Stock Levels',
        path: '/stock/levels',
        component: <StockLevels />,
        layout: '/admin',
      },
      {
        name: 'Stock Movements',
        path: '/stock/movement',
        component: <StockMovements />,
        layout: '/admin',
      }
    ],
  },
  // // --- Users
  {
    name: 'Users',
    path: '/users',
    icon: <Icon as={MdSupervisedUserCircle} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'All users',
        path: '/users/all',
        component: <AllUsers />,
        layout: '/admin',
      },
      {
        name: 'Add New User',
        path: '/users/add',
        component: <NewUser />,
        layout: '/admin',
      }
    ],
  },
    // // --- Settings
    {
      name: 'Settings',
      path: '/settings',
      icon: <Icon as={MdOutlineSettings} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Business Settings',
          path: '/settings/business',
          component: <BusinessSettingsPage />,
          layout: '/admin',
        },
        {
          name: 'Notifications',
          path: '/settings/notifications',
          component: <NotificationsPage />,
          layout: '/admin',
        },
        {
          name: 'My Profile',
          path: '/settings/me',
          component: <AllUsers />,
          layout: '/admin',
        },
        {
          name: 'Audit',
          path: '/settings/audit',
          component: <AllUsers />,
          layout: '/admin'
        }
      ],
    },
    // // --- Help
    {
      name: 'Help & Support',
      path: '/help',
      icon: <Icon as={MdHelp} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Documentation',
          path: '/help/docs',
          component: <Documentation />,
          layout: '/admin',
        },
        {
          name: 'Contact Support',
          path: '/help/support',
          component: <AllUsers />,
          layout: '/admin',
        },
        {
          name: 'Feedback',
          path: '/help/feedback',
          component: <AllUsers />,
          layout: '/admin',
        }
      ],
    },
  // --- Authentication ---
  {
    name: 'Logout',
    path: '/auth/logout',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <LogOutPage />,
    layout: '/admin',
  },
];

export default routes;
