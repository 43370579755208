import React, {
    useRef,
    useState,
    useEffect,
    useCallback,
    useMemo
  } from 'react';
  import {
    Box,
    Button,
    SimpleGrid,
    Flex,
    useToast,
    Heading,
    useDisclosure
  } from '@chakra-ui/react';
  import axios from 'axios';
  
  // The React FullCalendar type
  import FullCalendar from '@fullcalendar/react';
  
  import EventCalendar from 'components/calendar/EventCalendar';
  import { useApi } from 'components/hooks/useApi';
  import { useToken } from 'components/hooks/useToken';
  import { useTenant } from 'components/hooks/useTenant';

  import { Job } from 'components/types/jobs';
  import { Order } from 'components/types/orders';
  import { Supplier } from 'components/types/suppliers';
  import { SupplierByIDResponse } from 'components/types/suppliers';
  import JobDetailsModal from '../jobs/components/jobDetailsModal';
  import OrderDetailsModal from '../orders/history/components/orderDetailsModal';

  export default function CalendarPage() {
    const toast = useToast();
    const apiUrl = useApi();
  
    const accessToken = useToken();
    const tenant = useTenant();    
  
    const [jobs, setJobs] = useState<Job[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [currentDisplay, setCurrentDisplay] = useState("");
    const calendarRef = useRef<FullCalendar>(null);
    const [selectedJob, setSelectedJob] = useState<Job | null>(null);
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
    const [suppliersMap, setSuppliersMap] = useState<Record<number, Supplier>>({});

    const {
        isOpen: isJobOpen,
        onOpen: onJobOpen,
        onClose: onJobClose,
      } = useDisclosure();
      const {
        isOpen: isOrderOpen,
        onOpen: onOrderOpen,
        onClose: onOrderClose,
      } = useDisclosure();

      const handleDatesSet = (info: any) => {
        const date = info.view.currentStart;
        const displayString = date.toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
        setCurrentDisplay(displayString);
      };

    const handleEventClick = useCallback((info: any) => {
        const { type, jobId, orderId } = info.event.extendedProps || {};
    
        if (type === "job" && jobId) {
          const job = jobs.find((j) => j.id === jobId);
          if (job) {
            setSelectedJob(job);
            onJobOpen();
          }
        } else if (type === "order" && orderId) {
          const order = orders.find((o) => o.id === orderId);
          if (order) {
            setSelectedOrder(order);
            onOrderOpen();
          }
        }
      }, [jobs, orders, onJobOpen, onOrderOpen]);

    const fetchJobs = useCallback(async () => {
      if (!accessToken || !tenant) return;
      try {
        const response = await axios.get<{ data: { Jobs: Job[] } }>(
          `${apiUrl}/jobs`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: { tenant },
          }
        );
        setJobs(response.data.data.Jobs || []);
        setError(null);
      } catch (err: any) {
        setError(err.response?.data?.message || 'Error fetching jobs');
        toast({
          title: 'Error',
          description: err.response?.data?.message || 'Error fetching jobs',
          status: 'error',
          duration: 4000,
        });
      }
    }, [apiUrl, accessToken, tenant, toast]);
  
    const fetchOrders = useCallback(async () => {
        if (!accessToken || !tenant) return;
        try {
          const response = await axios.get<{ data: { Orders: Order[] } }>(
            `${apiUrl}/orders`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
              params: { tenant },
            }
          );
          setOrders(response.data.data.Orders || []);
          setError(null);
        } catch (err: any) {
          setError(err.response?.data?.message || 'Error fetching orders');
        }
      }, [apiUrl, accessToken, tenant]);
    
      const fetchSuppliersForOrders = useCallback(async (ordersList: Order[]) => {
        if (!accessToken || !tenant || ordersList.length === 0) return;
        try {
          const supplierIds = Array.from(
            new Set(ordersList.map(o => o.supplier_id))
          );
    
          const fetchPromises = supplierIds.map(async (id) => {
            const res = await axios.get<SupplierByIDResponse>(`${apiUrl}/suppliers/${id}`, {
              headers: { Authorization: `Bearer ${accessToken}` },
              params: { tenant },
            });
            console.log (res.data.data);
            return res.data.data;
          });
    
          const suppliersData = await Promise.all(fetchPromises);
    
          const newMap: Record<number, Supplier> = {};
          suppliersData.forEach((supplier) => {
            newMap[supplier.id] = supplier;
          });
    
          setSuppliersMap(newMap);
        } catch (err) {
          console.error('Error fetching suppliers for orders:', err);
        }
      }, [apiUrl, accessToken, tenant]);
  
      useEffect(() => {
        const loadData = async () => {
          setLoading(true);
          await Promise.all([fetchJobs(), fetchOrders()]);
          setLoading(false);
        };
        loadData();
      }, [fetchJobs, fetchOrders]);
    
      useEffect(() => {
        if (orders.length > 0) {
          fetchSuppliersForOrders(orders);
        }
      }, [orders, fetchSuppliersForOrders]);

    const events = useMemo(() => {
      const jobEvents = jobs.map((job) => ({
        title: job.name,
        start: job.date,
        end: job.date,
        backgroundColor: '#68D391',
        borderColor: 'transparent',
        className: "info",
        extendedProps: {
            type: "job" as const,
            jobId: job.id,
          },
      }));
  
      const orderEvents = orders.map((order) => {
        const supplierObj = suppliersMap[order.supplier_id];
        const supplierName = supplierObj?.name || order.supplier_name || 'Unknown Supplier';
  
        return {
          title: `Order from - ${supplierName}`,
          start: order.due_date,
          end: order.due_date,
          backgroundColor: '#805AD5',
          borderColor: 'transparent',
          className: "warning",
          extendedProps: {
            type: "order" as const,
            orderId: order.id,
          },
        };
      });
  
      return [...jobEvents, ...orderEvents];
    }, [jobs, orders, suppliersMap]);
  
    const handlePrev = () => {
      calendarRef.current?.getApi().prev();
      updateDisplayDate();
    };
  
    const handleNext = () => {
      calendarRef.current?.getApi().next();
      updateDisplayDate();
    };
  
    const handleToday = () => {
      calendarRef.current?.getApi().today();
      updateDisplayDate();
    };
  
    const firstOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const updateDisplayDate = () => {
        const calendarApi = calendarRef.current?.getApi();
        if (calendarApi) {
          const date = calendarApi.getDate();
          const displayString = date.toLocaleString("default", {
            month: "long",
            year: "numeric",
          });
          setCurrentDisplay(displayString);
        }
      };
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <SimpleGrid columns={1} spacing={4}>
        <Flex direction="column" align="center" mb={4}>
            <Flex mb={2}>
                <Button variant='action' px='24px' fontSize='sm' fontWeight='700' onClick={handlePrev} mr={4}>
                ←
                </Button>
                <Button variant='action' px='24px' fontSize='sm' fontWeight='700' onClick={handleToday} mr={4}>
                Today
                </Button>
                <Button variant='action' px='24px' fontSize='sm' fontWeight='700' onClick={handleNext}>
                →
                </Button>
            </Flex>
            <Heading size="md" mt={2}>
                {currentDisplay}
            </Heading>
    </Flex>
          {loading && <p>Loading data...</p>}
          {error && <p style={{ color: 'red' }}>{error}</p>}
  
          {!loading && !error && (
            <EventCalendar
              ref={calendarRef}       
              calendarData={events}   
              initialDate={firstOfMonth.toISOString().split('T')[0]}
              onEventClick={handleEventClick}
              onDatesSet={handleDatesSet}
            />
            
          )}
          <JobDetailsModal
            isOpen={isJobOpen}
            onClose={() => {
            setSelectedJob(null);
            onJobClose();
            }}
            job={selectedJob}
            refreshJobs={fetchJobs}
        />

        <OrderDetailsModal
            isOpen={isOrderOpen}
            onClose={() => {
            setSelectedOrder(null);
            onOrderClose();
            }}
            orderId={selectedOrder?.id || null}
            orderStatus={selectedOrder?.status || "Pending"}
        />
        </SimpleGrid>
      </Box>
    );
  }
  