import { Step } from "react-joyride";

export const helpSteps: Step[] = [
    {
        target: 'button[aria-label="Add Item"]',
        content: 'Use this button to add a new type of item, or a new batch of an existing item',
        placement: 'top'
    },
    {
        target: 'button[aria-label="Add Supplier"',
        content: 'Use this button to add a new supplier, which you can then use to attach new items to',
        placement: 'top'
    },
    {
        target: 'button[aria-label="Add Category"]',
        content: 'This button allows you to add a new category of items to your inventory',
        placement: 'top'
    },
    {
        target: 'body',
        content: (
          <div>
            <p>
              For more information check out the inventory section in the {' '}
              <a href="/admin/help/docs" target="_blank" rel="noopener noreferrer">
                <strong>documentation (click me!)</strong>
              </a>{' '}
            </p>
          </div>
        ),
        placement: 'center',
      },
]