// Chakra imports
import { Flex, Text, useColorModeValue, Badge } from '@chakra-ui/react';

export default function JobDisplay(props: { date: string; name: string; status: string; cost: number;[x: string]: any }) {
	const { date, name, status, cost, ...rest } = props;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const formatDate = (date: string) => {
		const d = new Date(date);
		return d.toLocaleDateString('en-GB', { month: 'long', day: 'numeric', year: 'numeric' });
	}
	return (
		<Flex justifyContent='center' alignItems='center' w='100%' {...rest}>
			<Flex direction='column' align='start' me='auto'>
				<Text color={textColor} fontSize='md' me='6px' fontWeight='700'>
					{name} - £{cost}
				</Text>
				<Text color='secondaryGray.600' fontSize='sm' fontWeight='500'>
					{formatDate(date)}
				</Text>
			</Flex>
			<Badge
				ms='auto'
				colorScheme={status === 'Open' ? 'yellow' : 'green'}
				color={status === 'Open' ? 'red.500' : 'green.500'}
				fontSize='sm'>
				{status}
			</Badge>
		</Flex>
	);
}
