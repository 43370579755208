import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Stack,
  useDisclosure,
  useToast,
  Box,
  IconButton,
  Text
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useApi } from 'components/hooks/useApi';
import { useToken } from 'components/hooks/useToken';
import { useTenant } from 'components/hooks/useTenant';
import axios from 'axios';
import InputField from 'components/fields/InputField';
import DayDateField from 'components/fields/DayDateField';

interface AddJobModalProps {
  refreshJobs: () => void;
  fakeSave?: boolean;
}

interface JobItemRow {
  item_id: string;
  quantity: string;
}

interface Item {
  id: number;
  name: string;
  cost_price: number;
}

export default function AddJobModal({ refreshJobs, fakeSave }: AddJobModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const apiUrl = useApi();
  const accessToken = useToken();
  const tenant = useTenant();
  const toast = useToast();

  const [name, setName] = useState('');
  const [jobDate, setJobDate] = useState<Date | null>(new Date());
  const [status, setStatus] = useState('Open');
  const [jobItems, setJobItems] = useState<JobItemRow[]>([{ item_id: '', quantity: '' }]);
  const [items, setItems] = useState<Item[]>([]);
  const [loadingItems, setLoadingItems] = useState(false);
  const [totalCost, setTotalCost] = useState<number>(0);

  // Fetch items
  const fetchItems = useCallback(async () => {
    if (!accessToken || !tenant) return;
    try {
      setLoadingItems(true);
      const res = await axios.get<{ data: { Items: Item[] } }>(
        `${apiUrl}/inventory/items`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant },
        }
      );
      setItems(res.data.data.Items || []);
    } catch (err) {
      console.error('Error fetching items:', err);
    } finally {
      setLoadingItems(false);
    }
  }, [apiUrl, accessToken, tenant]);

  useEffect(() => {
    if (isOpen) {
      fetchItems();
    }
  }, [isOpen, fetchItems]);

  // Update total cost when jobItems change
  useEffect(() => {
    let newTotal = 0;
    jobItems.forEach((row) => {
      const item = items.find((it) => it.id.toString() === row.item_id);
      if (item && row.quantity) {
        newTotal += item.cost_price * parseInt(row.quantity, 10);
      }
    });
    setTotalCost(newTotal);
  }, [jobItems, items]);

  // Handlers
  const handleJobItemChange = (index: number, field: keyof JobItemRow, value: string) => {
    setJobItems((prev) => {
      const newArr = [...prev];
      newArr[index] = { ...newArr[index], [field]: value };
      return newArr;
    });
  };

  const addJobItemRow = () => {
    setJobItems((prev) => [...prev, { item_id: '', quantity: '' }]);
  };

  const removeJobItemRow = (index: number) => {
    setJobItems((prev) => prev.filter((_, i) => i !== index));
  };

  // Submit job
  const handleSubmit = async () => {
    if (!name.trim() || !jobDate) {
      toast({
        title: 'Validation Error',
        description: 'Name and Date are required.',
        status: 'warning',
        duration: 3000,
      });
      return;
    }
    if (fakeSave) {
      toast({ title: 'Job Created (example)', status: 'info', duration: 3000 });
      resetForm();
      return;
    }
    for (const row of jobItems) {
      if (!row.item_id || !row.quantity) {
        toast({ title: 'Validation Error', description: 'Each job item must have an item and quantity.', status: 'warning' });
        return;
      }
    }

    try {
      const payload = {
        jobs: [
          {
            tenant_id: parseInt(tenant?.toString() || '0'),
            name,
            status,
            date: jobDate,
            cost: totalCost,
            items: jobItems.map((row) => ({
              item_id: parseInt(row.item_id, 10),
              quantity: parseInt(row.quantity, 10),
            })),
          },
        ],
      };
      await axios.post(`${apiUrl}/jobs`, payload, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { tenant },
      });

      toast({ title: 'Job Created', status: 'success', duration: 3000 });
      resetForm();
      refreshJobs();
    } catch (err: any) {
      toast({
        title: 'Error creating job',
        description: err.response?.data?.message || 'Something went wrong.',
        status: 'error',
      });
    }
  };

  // Reset form after submission
  const resetForm = () => {
    setName('');
    setJobDate(new Date());
    setStatus('Open');
    setJobItems([{ item_id: '', quantity: '' }]);
    setTotalCost(0);
    onClose();
  };

  return (
    <>
      <Button variant="brand" fontSize="sm" borderRadius="16px" h="32px" onClick={onOpen}>
        Add New Job
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add a New Job</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="20px">
              <InputField label="Job Name" value={name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)} isRequired />
              <DayDateField label="Date" value={jobDate} onChange={(val) => setJobDate(val)} isRequired />

              {/* JOB ITEMS */}
              <Box borderWidth="1px" borderRadius="md" p="4" width="100%">
                <Stack spacing="0" mb="4" width="100%">
                  {jobItems.map((row, idx) => (
                    <Stack key={idx} borderWidth="0px" p="1" borderRadius="md" spacing="1" width="100%">
                      <Stack direction="row" spacing="10px" align="center" width="100%">
                        
                        {/* SEARCHABLE ITEM DROPDOWN */}
                        <Box flex="1">
                          <Select
                            options={items.map((item) => ({
                              value: item.id.toString(),
                              label: `${item.name} (£${item.cost_price.toFixed(2)})`,
                            }))}
                            value={items
                              .map((item) => ({
                                value: item.id.toString(),
                                label: `${item.name} (£${item.cost_price.toFixed(2)})`,
                              }))
                              .find((option) => option.value === row.item_id) || null}
                            onChange={(selectedOption) =>
                              handleJobItemChange(idx, "item_id", selectedOption?.value || "")
                            }
                            isDisabled={loadingItems}
                            isSearchable
                            placeholder="Select an item"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: "100%", // Ensure it takes full available space
                              }),
                            }}
                          />
                        </Box>

                        {/* QUANTITY FIELD */}
                        <Box flex="0.3">
                          <InputField
                            label="Qty"
                            type="number"
                            placeholder="Quantity"
                            value={row.quantity}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              handleJobItemChange(idx, "quantity", e.target.value)
                            }
                            width="100%"
                          />
                        </Box>

                        {/* DELETE BUTTON */}
                        {jobItems.length > 1 && (
                          <IconButton
                            bg={"transparent"}
                            aria-label="Remove Item"
                            icon={<CloseIcon h={"10px"} w={"10px"} color={"red.500"} />}
                            colorScheme="blackAlpha"
                            onClick={() => removeJobItemRow(idx)}
                          />
                        )}
                      </Stack>
                    </Stack>
                  ))}
                  <Button onClick={addJobItemRow} variant="outline" width="100%">
                    Add Another Item
                  </Button>
                </Stack>
              </Box>

              {/* Total Cost Display */}
              <Text fontWeight="bold" fontSize="lg">
                Total Cost: £{totalCost.toFixed(2)}
              </Text>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="brand" onClick={handleSubmit}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
